<template>

  <div>
    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">
            <form v-on:submit.prevent="submitForm" @keydown="clearError" class="row">
              <div class="col-12">
                <h1>Sign Up</h1>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="name">Name</label>
                <input id="name" type="text" name="name" v-model="form.name"  class="lss-form-control" placeholder="Nick Monckey">
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="email">Email</label>
                <input id="email" type="email" name="email" v-model="form.email" class="lss-form-control" placeholder="example@example.com">
              </div>

              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="email">Mobile</label>
                <input id="mobile" type="mobile" name="mobile" v-model="form.mobile" class="lss-form-control" placeholder="--- -- -- --">
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="password">Password</label>
                <div class="custom-input-group input-group-merge">
                  <input id="password" :type="InputType" name="password" v-model="form.password" class="lss-form-control" placeholder="********">
                  <div id="changePasswordView" class="input-group-append">
                    <div class="input-group-text"  v-on:click="show_password">
                      <div :class="isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div :class="!isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye"></i>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="re-password">Re-Password</label>
                <div class="custom-input-group input-group-merge">
                  <input id="re-password" :type="InputType" name="repassword" v-model="form.repassword" class="lss-form-control" placeholder="********">
                  <div class="input-group-append">
                    <div class="input-group-text"  v-on:click="show_password">
                      <div :class="isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div :class="!isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-40">
                <button class="btn-sent-yellow-lg d-block d-sm-none">Sign In</button>
                <div class="d-block d-md-flex text-center mt-4 mt-sm-0">
                  <div class="mr-0 mr-sm-3">Already have an account?</div>
                  <a href="/" class="ms-1">Sign In</a>
                </div>
                <button class="btn-sent-yellow-lg d-none d-sm-block">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
export default {
  name: "Signup",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isActive: true,
      StatusPassword:'fas fa-eye-slash',
      InputType:'password',
      error: false,
      errors: {},
      form: {
        name: '',
        email: '',
        password: '',
        repassword: '',
      }

    }
  },
}
</script>

<style scoped>

</style>
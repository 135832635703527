import { createRouter, createWebHistory } from 'vue-router'
import Signin from '../views/auth/Signin.vue'
import Recover from '../views/auth/RecoverPassword.vue'
import Signup from '../views/auth/Signup.vue'
import Home from '../views/Home.vue'


const routes = [
  {
    path: '/',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },

  {
    path: '/Recover-password',
    name: 'Recover',
    component: Recover
  },
  {
    path:'/Signup',
    name:"Signup",
    component: Signup
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
